import {Component, ElementRef, Input, OnChanges, OnInit, QueryList, ViewChildren,AfterContentInit, HostListener } from "@angular/core";
import {UxHelper} from "../../../../../clientCommon/helper/uxHelper";
import {LoaderPeopleSearch} from "../../../../peopleSearch/loaderPeopleSearch";
import { AddressField, AddressUtils } from '@common/utils/addressUtils';
import { LogUtils } from "@common/utils/logUtils";

@Component({
  selector: 'ux-3-1-loader-name-search',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class Ux3Dot1LoaderNameSearchComponent implements OnInit, OnChanges, AfterContentInit {
  @Input() base: LoaderPeopleSearch;
  @Input() uxHelper: UxHelper;

  @ViewChildren('status') status: QueryList<ElementRef>;
  @ViewChildren('loaderImage') loaderImage: QueryList<ElementRef>;

  submitted = false;
  addressField: AddressField = AddressUtils.getAddressField("US");
  location = null;
  time: number = 10;
  list = null;

  isMobile: boolean = false;

  constructor() {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkWindowSize();
  }  
  checkWindowSize() {
    this.isMobile = window.innerWidth <= 576;    
  }

  init() {
    this.list = this.uxHelper.getUxcomp('progressor.list');
    this.checkWindowSize();

    LogUtils.debug(this.list);

    LogUtils.debug('nested => ', this.base.fakeProgressors.nested.use, this.base.fakeProgressors.nested.question);
    LogUtils.debug('isThinMatchEnabled => ', this.base.isThinMatchEnabled);
    LogUtils.debug('isThinMatchCase => ', this.base.isThinMatchCase);
    LogUtils.debug('research => ', this.base.reSearchFlag);
    LogUtils.debug('isMobile => ', this.isMobile);
    
  }

  ngOnInit() {    
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }

  search() {
    this.submitted = true;
    if (this.base.isValidCityState())
      this.base.reSearch({});
  }

  ngAfterContentInit(): void {
    this.checkStatus();
    this.location = this.addressField.getAdministrativeAreaOptionsArray().find(value => value.code == this.location)?.name;
  }

  checkStatus() {
    let elementList = [];

    const interval = setInterval(() => {
      let random;

      if (elementList.length > 12) {
        clearInterval(interval)
      }

      do {
        if (elementList.length > 12) {
          break;
        }
        random = Math.floor(Math.random() * 13);
      } while (elementList.includes(random));

      elementList.push(random)

      this.status.map((element: ElementRef<HTMLSpanElement>, index) => {
        if (random == index) {
          element.nativeElement.classList.replace('spin', 'checkmark')
        }
      })
    }, (this.time * 1000)/6)

  }
}
